<template>
    <!-- Lead Form -->
    <!-- Section content start-->
    <section class="content-section">
      <!-- Progress bar-->
      <div class="progress-bar">
        <div class="progress" v-bind:style="{ width: progressWidth + '%'}"></div>
      </div>
      <!-- Form section-->
      <div class="form-section">
        <transition name="fade" mode="out-in" appear>
          <!-- Frage 1 Tatvorwurf-->
          <Accusation key="1" v-if="step == 1" />

          <!-- Frage 2 Erweiterter Tatvorwurf-->
          <AccusationExtended key="2" v-if="step == 2"/>
          
          <!-- Frage 3 RSV-->
          <Insurance key="3" v-if="step == 3"/>
          
          <!-- Frage 4 Post von Behörde-->
          <PostFromOffice key="4" v-if="step == 4"/>

          <!-- Frage 5 Geschwindigkeitsverstoß (Wo)-->
          <SpeedAccusationLocation key="5" v-if="step == 5"/>

          <!-- Frage 5b Geschwindigkeitsverstoß (Wie schnell)-->
          <SpeedAccusationSpeed key="6" v-if="step == 6"/>

          <!-- Frage 6 Abstandsverstoß (Wie schnell)-->
          <DistanceAccusationSpeed key="7" v-if="step == 7"/>

          <!-- Frage 6b Abstandsverstoß (Wieviel Abstand)-->
          <DistanceAccusationDistance key="8" v-if="step == 8"/>

          <!-- Frage 7 Rotlichverstoß (Andere Teilnehmer gefährdet)-->
          <RedlightAccusationRisk key="9" v-if="step == 9"/>

          <!-- Frage 7b Rotlichverstoß (Etwas beschädigt)-->
          <RedlightAccusationDamage key="10" v-if="step == 10"/>

          <!-- Frage 7c Rotlichverstoß (Wie lange Ampel rot)-->
          <RedlightAccusationTime key="11" v-if="step == 11"/>

          <!-- Frage 8 Wieviel Punkte-->
          <Points key="12" v-if="step == 12"/>

          <!-- Frage 9 Welches Dokument erhalten-->
          <Document key="13" v-if="step == 13"/>

          <!-- Frage 10 Letzte 12 Monate geblitzt-->
          <Flashed key="14" v-if="step == 14"/>

          <!-- Frage 11 Führerschein angewiesen-->
          <DriversLicense key="15" v-if="step == 15"/>

          <!-- Frage 12 Persönliche Daten-->


          <section key="16" v-if="step == 16">  
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="sendData">
                <div class="section" key="personalData">
                    <div class="section-title">
                        <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
                        <h3>Fast geschafft! Wohin dürfen wir Ihr Ergebnis senden?</h3>
                        <p class="green-color">Die Prüfung Ihres Falles ist für Sie <b>kostenlos & unverbindlich</b>. Es erfolgt keine Weitergabe Ihrer Daten!</p>
                    </div>
                    <div class="form-container form-data">
                        <div class="input-row two-input">
                            <div class="wrap-input">
                                <ValidationProvider mode="eager" name="vorname" rules="required" v-slot="{ errors }">
                                    <input type="text" v-model="user.firstname"><span class="label-input" v-bind:class="{ filled : user.firstname.length >= 1 }">Ihr Vorname *</span>
                                    <p class="form-error">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                            <div class="wrap-input">
                                <ValidationProvider mode="eager" name="nachname" rules="required" v-slot="{ errors }">
                                    <input type="text" v-model="user.name"><span class="label-input" v-bind:class="{ filled : user.name.length >= 1 }">Ihr Nachname *</span>
                                    <p class="form-error">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input">
                                <ValidationProvider mode="eager" name="email" rules="required|email" v-slot="{ errors }">
                                    <input type="email" v-model="user.email"><span class="label-input" v-bind:class="{ filled : user.email.length >= 1 }">Ihre E-Mail Adresse *</span>
                                    <p class="form-error">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="input-row">
                            <div class="wrap-input">
                                <MazPhoneNumberInput
                                    v-model="phone"
                                    type="tel"
                                    default-country-code="DE"
                                    :no-country-selector=true
                                    :only-countries="['DE']"
                                    placeholder="Ihre Telefonnummer*"
                                    @update="phonenumberResult = $event"
                                    :translations="{
                                        countrySelectorLabel: 'Land',
                                        countrySelectorError: 'Bitte Land wählen',
                                        phoneNumberLabel: 'Ihre Telefonnummer',
                                        example: 'Beispiel:'
                                    }"
                                />
                                <!--<ValidationProvider mode="eager" name="phone" rules="required" v-slot="{ errors }">
                                    <input type="phone" v-model="phone"><span class="label-input" v-bind:class="{ filled : phone.length >=1 }">Ihre Telefonnummer *</span>
                                    <p class="form-error">{{ errors[0] }}</p>
                                </ValidationProvider> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-control" key="18">
                    <transition name="fade">
                        <button type="submit" :disabled="invalid == true || formSubmitted == true" class="next submit" v-if="step == 16"><span v-if="isLoading == true"></span>Kostenlose Auswertung anfordern</button>
                        
                    </transition>
                    <p v-show="step > 1"><a class="prev" href="#" v-on:click.prevent="prev()">
                        <svg>
                        <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
                        </svg>Zurück</a>
                    </p>
                </div>
                
            </form>
          </ValidationObserver>
          </section>
          <!-- Result-->
          <div class="section result-section" key="17" v-if="step == 17">
            <div class="section-title">
              <div class="icon-document"><img src="@/assets/images/icons-check-document-active.png"></div>
              <h3>Anfrage erfolgreich eingegangen. <br> Ihre Fallnummer lautet {{user.casenumber}}</h3>
              <p>Vielen Dank für Ihre Anfrage! Ihr Fall ist nun in Bearbeitung und wir werden uns umgehend bei Ihnen melden.</p>
            </div>
          </div>
        </transition>
        <div class="form-control" v-if="step != 16" key="18">
          <transition name="fade">
            <button class="next" v-if="(checked >= 1) & step < 16" v-on:click="next()"><span v-if="isLoading == true"></span>Weiter</button>
            
          </transition>
          <p v-show="step > 1"><a class="prev" href="#" v-on:click.prevent="prev()">
            <svg>
              <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
            </svg>Zurück</a>
          </p>
        </div>
      </div>
    </section>
</template>
<script>
/*eslint-disable no-useless-escape*/
import { MazPhoneNumberInput } from 'maz-ui';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import axios from 'axios';
import { mapState, mapMutations } from 'vuex';

import { logger } from '@/common/logger';

import { scrollTo } from 'vue-scrollto';

// Implement own mail regex, due to not correctly identify wrong mails like 'mail@-example.de'
extend('email', value => {
    const EMAILREG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    
    if (EMAILREG.test(value)) {
        return true;
    }
    return 'Bitte eine gültige E-Mail-Adresse eingeben';
});

// Override the default message.
extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
});

export default {
    name: 'Form',
    components: {
        ValidationProvider,
        ValidationObserver,
        MazPhoneNumberInput,
        Accusation: () => import('./questions/Accusation'),
        AccusationExtended: () => import('./questions/AccusationExtended'),
        DistanceAccusationDistance: () => import('./questions/DistanceAccusationDistance'),
        DistanceAccusationSpeed: () => import('./questions/DistanceAccusationSpeed'),
        Document: () => import('./questions/Document'),
        DriversLicense: () => import('./questions/DriversLicense'),
        Flashed: () => import('./questions/Flashed'),
        Insurance: () => import('./questions/Insurance'),
        //PersonalData: () => import('./questions/PersonalData'),
        Points: () => import('./questions/Points'),
        PostFromOffice: () => import('./questions/PostFromOffice'),
        RedlightAccusationDamage: () => import('./questions/RedlightAccusationDamage'),
        RedlightAccusationRisk: () => import('./questions/RedlightAccusationRisk'),
        RedlightAccusationTime: () => import('./questions/RedlightAccusationTime'),
        SpeedAccusationLocation: () => import('./questions/SpeedAccusationLocation'),
        SpeedAccusationSpeed: () => import('./questions/SpeedAccusationSpeed'),
    },
    data() {
        return {
            progressWidth: 5,
            phone: null,
            phonenumberResult: null,
            formSubmitted: false
        };
    },
    computed: mapState({
        user: (state) => state.user,
        step: (state) => state.step,
        isLoading: (state) => state.isLoading,
        checked: (state) => state.checked,
    }),
    methods: {
        ...mapMutations([
            'incrementChecked',
            'decrementChecked',
            'setChecked',
            'setStep',
            'incrementStep',
            'decrementStep',
            'setLoading',
            'updatePhoneNumber',
            'createCaseNumber'
        ]),
        checkLeadPoints(){
            //Lead Points 150
            if ((this.user.accusation == 'Geschwindigkeitsverstoß' || this.user.accusation == 'Abstandsverstoß' || this.user.accusation == 'Rotlichtverstoß') && this.user.insurance == 'Ja' && this.user.postFromOffice == 'Ja') {
                return 150;
            }

            //Lead Points 100
            if ((this.user.accusation == 'Geschwindigkeitsverstoß' || this.user.accusation == 'Abstandsverstoß' || this.user.accusation == 'Rotlichtverstoß') && this.user.insurance == 'Nein' && this.user.postFromOffice == 'Ja') {
                return 100;
            }

            //Lead Points 25
            if ((this.user.accusation == 'Geschwindigkeitsverstoß' || this.user.accusation == 'Abstandsverstoß' || this.user.accusation == 'Rotlichtverstoß') && this.user.insurance == 'Ja' && this.user.postFromOffice == 'Nein') {
                return 25;
            }

            //Lead Points 1
            if (this.user.accusation == 'Handy / Drogen / Parken') {
                return 1;
            }

        },
        prev() {
            if (this.step == 12) {
                switch (this.user.accusation) {
                    case 'Geschwindigkeitsverstoß':
                        this.setStep(6);
                        break;
                    case 'Abstandsverstoß':
                        this.setStep(8);
                        break;
                        
                    case 'Rotlichtverstoß':
                        this.setStep(11);
                        break;
                
                    default:
                        this.setStep(4);
                        break;
                }
            } else if (this.step == 9){
                this.setStep(4);
            } else if (this.step == 7){
                this.setStep(4);
            } else if (this.step == 3){
                if (this.user.accusation == 'Handy / Drogen / Parken') {
                    this.setStep(2);
                }else{
                    this.setStep(1);
                }
            } else {
                this.decrementStep();
            }
            this.incrementChecked();
        },
        next() {
            this.setLoading(true);
            setTimeout(
            () =>  {
                /*For the next and prev navigation and the visibility of the next button,
                we have to check if the next question is already filled. If yes, show next button.
                */
                switch (this.step) {
                    // Tatvorwurf
                    case 1:
                        if (this.user.accusation == 'Handy / Drogen / Parken') {
                            if(this.user.accusationExtended != ''){
                                this.setChecked(1);
                            }else{
                                this.setChecked(0);
                            }
                            this.setStep(2);
                        }else{
                            if(this.user.insurance != ''){
                                this.setChecked(1);
                            }else{
                                this.setChecked(0);
                            }
                            this.setStep(3);
                            this.user.accusationExtended = '';
                        }
                        break;
                    // Tatvorwurf Erweitert
                    case 2:
                        if(this.user.insurance != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // RSV    
                    case 3:
                        if(this.user.postFromOffice != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Post von Behörde    
                    case 4:
                        switch (this.user.accusation) {
                            case 'Geschwindigkeitsverstoß':
                                if(this.user.speedAccusationLocation != ''){
                                    this.setChecked(1);
                                }else{
                                    this.setChecked(0);
                                }
                                // clear values for other accusations
                                this.user.distanceAccusationDistance = '';
                                this.user.distanceAccusationSpeed = '';
                                this.user.redlightAccusationRisk = '';
                                this.user.redlightAccusationDamage = '';
                                this.user.redlightAccusationTime = '';

                                this.setStep(5);
                                break;
                            case 'Abstandsverstoß':
                                if(this.user.distanceAccusationSpeed != ''){
                                    this.setChecked(1);
                                }else{
                                    this.setChecked(0);
                                }
                                // clear values for other accusations
                                this.user.speedAccusationLocation = '';
                                this.user.speedAccusationSpeed = '';
                                this.user.redlightAccusationRisk = '';
                                this.user.redlightAccusationDamage = '';
                                this.user.redlightAccusationTime = '';

                                this.setStep(7);
                                break;
                                
                            case 'Rotlichtverstoß':
                                if(this.user.redlightAccusationRisk != ''){
                                    this.setChecked(1);
                                }else{
                                    this.setChecked(0);
                                }
                                // clear values for other accusations
                                this.user.speedAccusationLocation = '';
                                this.user.speedAccusationSpeed = '';
                                this.user.distanceAccusationDistance = '';
                                this.user.distanceAccusationSpeed = '';

                                this.setStep(9);
                                break;
                        
                            default:
                                if(this.user.points != ''){
                                    this.setChecked(1);
                                }else{
                                    this.setChecked(0);
                                }
                                // clear values for other accusations
                                this.user.speedAccusationLocation = '';
                                this.user.speedAccusationSpeed = '';
                                this.user.distanceAccusationDistance = '';
                                this.user.distanceAccusationSpeed = '';
                                this.user.redlightAccusationRisk = '';
                                this.user.redlightAccusationDamage = '';
                                this.user.redlightAccusationTime = '';

                                this.setStep(12);
                                break;
                        }
                        break;
                    // Geschwindigkeitsverstoß Ort    
                    case 5:
                        if(this.user.speedAccusationSpeed != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Geschwindigkeitsverstoß Geschwindigkeit    
                    case 6:
                        if(this.user.points != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.setStep(12);
                        break;
                    // Abstandsverstoß (wie schnell)
                    case 7:
                        if(this.user.distanceAccusationDistance != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Abstandsverstoß (wieviel Abstand)
                    case 8:
                        if(this.user.points != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.setStep(12);
                        break;
                    // Rotlichverstoß (Andere Teilnehmer gefährdet)
                    case 9:
                        if(this.user.redlightAccusationDamage != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Rotlichverstoß (Etwas beschädigt)
                    case 10:
                        if(this.user.redlightAccusationTime != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Rotlichverstoß (Wie lange Ampel rot)
                    case 11:
                        if(this.user.points != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Wieviel Punkte
                    case 12:
                        if(this.user.document != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Welches Dokument
                    case 13:
                        if(this.user.flashed != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Letzte 12 Monate geblitzt
                    case 14:
                        if(this.user.driversLicense != ''){
                            this.setChecked(1);
                        }else{
                            this.setChecked(0);
                        }
                        this.incrementStep();
                        break;
                    // Führerschein angewiesen
                    // Persönliche Daten
                    case 15:
                    case 16:
                    default:
                        this.incrementStep();
                        break;
                }
                this.progressWidth = 100 / 16 * this.step; // 16 - it's count of question in form

                this.setLoading(false);
                scrollTo('body', 500, { easing: 'ease-in-out' });
            } , 500);
        },
        sendData() {
            if (!this.phonenumberResult.isValid) {
                return;
            }
            this.formSubmitted = true;
            this.$store.dispatch('setLoadingState', true);

            var points = this.checkLeadPoints();

            //TODO: gtm tracking
            //Check for  Premium Lead 150 / Normal Lead 100 / Schwacher Lead 25 / Schlechter Lead 1
            if (points == 150) {

                //Wert 4
                this.$gtag.event('conversion', { send_to: "AW-996491823/Pnh7CMnik3EQr4SV2wM" });
                
            }else if (points == 100) {

                //Wert 3
                this.$gtag.event('conversion', { send_to: "AW-996491823/jJPvCI7dpnEQr4SV2wM" });
                
            }else if (points == 25) {

                //Wert 2
                this.$gtag.event('conversion', { send_to: "AW-996491823/zjHXCI2OrXEQr4SV2wM" });
                
            }else {

                //Wert 1
                this.$gtag.event('conversion', { send_to: "AW-996491823/86BVCJHsk3EQr4SV2wM" });

            }

            //remove non-numbers from string
            this.updatePhoneNumber(this.phone);
            
            //Create casenumber and save to user in vuex store.
            this.createCaseNumber();

            //send user data to lead service and get created lead id
            axios({
                method: 'post',
                data: {
                    user: this.user
                },
                url: `${process.env.VUE_APP_API_LEADS_URL}/leads/insert_bk`,
                headers: {
                'Content-Type': 'application/json',
                },
            }).then((response) => {
                var leadId = response.data.leadId; // Extract the leadId string
                console.log('Received leadId:', leadId);

                if (leadId != '') {
                    /*redirect to upload service
                    lid = leadid, fn = casenumber, 
                    fwd is used to guide the user through all followed processes
                    pr = product
                    */
                    window.location= `${process.env.VUE_APP_FRONTEND_UPLOAD_URL}?lid=${leadId}&fn=${this.user.casenumber}&fwd=true&pr=BK`;
                } else {
                    logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email};Tel.: {Phone}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email, this.user.phone);
                    this.setStep(17);
                    this.formSubmitted = false;
                }
                this.$store.dispatch('setLoadingState', false);
            })
            .catch((error) => {
                logger.error(error);
                logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email};Tel.: {Phone}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email, this.user.phone);
                this.$store.dispatch('setLoadingState', false);
                this.formSubmitted = false;
            });
        }
    }
}
</script>